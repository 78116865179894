html,
body,
*[theme] {
  --shadow-1: 0 0 0 1px rgba(var(--neutral-1), 0.2) !important;
  --base-1: var(--base-0) !important;
  --base-2: var(--base-0) !important;
  --base-3: var(--base-0) !important;
  --base-4: var(--base-0) !important;
  --accent-1: 235, 65, 10 !important;
  --accent-1b: 245, 75, 20 !important;
  --accent-1c: 225, 55, 0 !important;
  --accent-2: 80, 30, 180 !important;
  --header-1: normal 16px/24px 'Archivo Black' !important;
  --header-2: normal 14px/24px 'Archivo Black' !important;
  --border-radius: 8px;
  font: var(--body-1) !important;
  color: var(--text-1);
}
kor-nav-bar kor-tab-item {
  border: unset;
  &[active] {
    color: rgb(var(--accent-1));
  }
}
kor-button {
  &.mobile {
    box-sizing: border-box;
    padding: 8px 12px;
    max-width: unset;
  }
}
// page transitions
app-home,
app-taxon,
app-search,
app-user,
app-place,
app-media {
  transition: 0.4s all ease-out;
  &.transition {
    opacity: 0;
    transform: translateY(8px);
  }
}
// skeleton loading
.skeleton {
  border-radius: var(--border-radius);
  position: relative;
  overflow: hidden;
  background-color: rgba(var(--neutral-1), 0.05);
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(var(--neutral-1), 0) 0,
      rgba(var(--neutral-1), 0.05) 50%,
      rgba(var(--neutral-1), 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
